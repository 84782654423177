<template>
  <div>
    <div
      class="block-icon-reglement-fac mr-1"
      @click.prevent.stop="filialeSocieteFacture(item)"
      title="Societe facture"
    >
      <font-awesome-icon icon="clipboard-list" />
    </div>
    <!--GESTION DES SOCIETE FACTURES-->
    <v-dialog
      v-model="modalSocieteFacture"
      persistent
      max-width="1400px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">GESTION DES SOCIÉTÉS FACTURES </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalSocieteFacture('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="getSocieteFactureLoader" class="loading-block">
            <v-progress-circular
              class="ml-2"
              v-if="getSocieteFactureLoader"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <div v-else class="form-add">
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="2">
                <div
                  class="block-icon-table-facture fix-width mr-1"
                  @click.prevent.stop="addLinetSocieteFactureFiliale(item)"
                  title="Ajouter un exercice"
                >
                  <font-awesome-icon icon="plus" /> Ajouter une société à
                  facturer
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
            <div
              v-for="(societe, index) in getSocieteFacturesOfFiliale"
              :key="index"
              class="card-style"
            >
              <v-row>
                <v-col cols="10">
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="societe.societe_id"
                    :items="computedListFilialeLocateur"
                    @changeValue="societe.societe_id = $event"
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    label="Société *"
                    item-text="name"
                    item-value="id"
                    :auto-focus="false"
                    no-data-text="Aucune société trouvée"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1" class="edit-action">
                  <div
                    class="action-icon validate mr-2"
                    @click="handleSubmitFilialeSocieteFacture(societe)"
                    v-if="societe.societe_id"
                  >
                    <font-awesome-icon
                      title="Ajouter Société"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div
                    class="action-icon cancel mr-2"
                    @click="DeleteFilialeSocieteFacture(index, societe)"
                  >
                    <font-awesome-icon
                      title="Supprimer Société"
                      icon="times"
                      class="fa-2x bell1 m-2"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="error-block success-message" v-if="displayModification">
            Les modifications ont été appliquées avec succès.
          </div>
          <div
            v-if="getSocieteFactureErrors"
            class="error-block message-error-modal"
          >
            <ul v-if="Array.isArray(getSocieteFactureErrors)" class="mb-0">
              <li v-for="(e, index) in getSocieteFactureErrors" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getSocieteFactureErrors }}
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'societe-factures',
  props: {
    item: { required: true }
  },
  data() {
    return {
      filialeToUpdate: null,
      modalSocieteFacture: false,
      displayModification: false
    };
  },
  computed: {
    ...mapGetters([
      'getListFilialesLocateur',
      'getSocieteFacturesOfFiliale',
      'getSocieteFactureErrors',
      'getSocieteFactureLoader'
    ]),
    computedListFilialeLocateur() {
      return this.getListFilialesLocateur.filter(
        filiale => filiale.id != this.item.id
      );
    },
    computedFamilles() {
      return this.familles.map(item => {
        return { famille: item };
      });
    }
  },
  methods: {
    ...mapActions([
      'getAllSocieteFactureOfFiliale',
      'addSocieteFactureToFiliale',
      'removeFilialeSocieteFacture',
      'resteModalOfSocieteFacture'
    ]),
    async DeleteFilialeSocieteFacture(index, societeFacture) {
      this.resteModalOfSocieteFacture();
      if (societeFacture.hasOwnProperty('id')) {
        const response = await this.removeFilialeSocieteFacture({
          id: societeFacture.id
        });
        if (response == true) {
          this.displayModification = true;
          this.getSocieteFacturesOfFiliale.splice(index, 1);
          setTimeout(() => {
            this.displayModification = false;
          }, 2500);
        }
      } else {
        this.getSocieteFacturesOfFiliale.splice(index, 1);
      }
    },
    addLinetSocieteFactureFiliale(filiale) {
      this.getSocieteFacturesOfFiliale.push({
        filiale_id: filiale.id,
        societe_id: null,
        favori: false
      });
    },
    async handleSubmitFilialeSocieteFacture(societeFacture) {
      const response = await this.addSocieteFactureToFiliale({
        filiale_id: this.filialeToUpdate.id,
        societe_id: societeFacture.societe_id,
        favori: societeFacture.favori,
        societeFacture: societeFacture
      });
      if (response) {
        this.displayModification = true;
        setTimeout(() => {
          this.displayModification = false;
        }, 2500);
      }
    },
    async filialeSocieteFacture(filiale) {
      this.filialeToUpdate = { ...filiale };
      this.handleModalSocieteFacture('show');
      await this.getAllSocieteFactureOfFiliale(this.filialeToUpdate.id);
    },
    handleModalSocieteFacture(action) {
      if (action == 'show') {
        this.modalSocieteFacture = true;
      } else {
        this.modalSocieteFacture = false;
        this.resteModalOfSocieteFacture();
        this.filialeToUpdate = null;
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error-block {
  margin-left: 35%;
}
.success-message {
  color: green !important;
}
.edit-action {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  margin-top: 0 !important;
  width: 5%;
  display: flex;
  font-size: 7px;
  height: 80px;
  align-content: center;
  align-items: center;

  .action-icon {
    cursor: pointer;
  }

  .validate {
    color: green;
  }

  .cancel {
    color: #ab2525;
  }
}
.loading-block {
  margin-left: 50%;
  margin-top: 1%;
}
button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  color: #fff;
}
.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.fix-width {
  width: 185px !important;
  padding-bottom: 23px;
  margin-left: 44%;
  padding-left: 8px;
  margin-top: 5%;
}
</style>
